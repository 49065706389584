/**
  marker {
    ancestors: ""
    cord_x: "706941.9638"
    cord_y: "5146252.8241"
    cord_z: "1180.0574"
    cross_timeline: "0"
    d_i: "2019-01-24 12:17:45"
    d_m: "2019-01-24 17:01:55"
    id: "697"
    id_rilievo: "64"
    id_utente: "2"
    permesso: "1"
    rot_x: "1"
    rot_y: "0"
    rot_z: "0"
    scala: "1.000"
    status: "0"
    tipo: "0"
    titolo: "Prova!"
  }

*/
import apiClient from "utility/apiClient";

export const DEFAULT_MARKER = {
  titolo: "Nuovo marker",
  permesso: "2",
  x: 0,
  y: 0,
  z: 0,
  alpha: 0,
  beta: 0,
  gamma: 0,
  crossTimeline: false,
  hover: false,
  visible: true,
  scale: 1,
};

export const api = {
  fetch: ({ rilievoId }) =>
    apiClient()({
      action: "get_marker",
      data: {
        id_rilievo: rilievoId,
      },
    }).then(markers => markers.map(massageForStore)),
  set: marker => {
    return apiClient()({
      action: "set_marker",
      data: massageForAPI(marker),
    });
  },
  delete: ({ id }) =>
    apiClient()({
      action: "remove_marker",
      data: {
        id_marker: id,
      },
    }),
  update: ({ id, marker }) =>
    apiClient()({
      action: "set_marker",
      data: massageForAPI(marker),
    }),
  setPermissions: ({ id, permission }) =>
    apiClient({})({
      action: "permission_marker",
      data: {
        id_marker: id,
        permesso: permission,
      },
    }),
};

export function massageForStore(marker) {
  return {
    id: marker.id,
    titolo: marker.titolo,
    permesso: marker.permesso,
    x: parseFloat(marker.cord_x),
    y: parseFloat(marker.cord_y),
    z: parseFloat(marker.cord_z),
    alpha: parseInt(marker.rot_x, 10),
    beta: parseInt(marker.rot_y, 10),
    gamma: parseInt(marker.rot_z, 10),
    rilievoId: marker.id_rilievo,
    visible: true,
    type: marker.tipo,
    crossTimeline: marker.cross_timeline === "1",
    scale: parseFloat(marker.scala),
  };
}

function err(message) {
  throw new Error(`[marker] ${message}`);
}

export function massageForAPI(marker) {
  const m = { ...DEFAULT_MARKER, ...marker };

  return {
    id_marker: m.id,
    id_rilievo: m.rilievoId || err("Missing rilievoId"),
    cord_x: m.x,
    cord_y: m.y,
    cord_z: m.z,
    rot_x: m.alpha,
    rot_y: m.beta,
    rot_z: m.gamma,
    cross_timeline: m.crossTimeline ? "1" : "0",
    tipo: m.type,
    titolo: m.titolo,
    scala: m.scale,
  };
}
