import { createActions, handleActions } from "redux-actions";

import apiClient from "utility/apiClient";

/* -- actions -- */
export const fetchProjects = () => async dispatch => {
  dispatch(fetchProjectsRequest());

  try {
    const data = await apiClient({ cache: false })({
      action: "get_progetti",
    });

    dispatch(fetchProjectsSucceeded(data));
  } catch (err) {
    dispatch(fetchProjectsFailed(err));
  }
};

export const {
  fetchProjectsRequest,
  fetchProjectsSucceeded,
  fetchProjectsFailed,
} = createActions({
  FETCH_PROJECTS_REQUEST: projectId => ({ projectId }),
  FETCH_PROJECTS_SUCCEEDED: projects => ({ projects }),
  FETCH_PROJECTS_FAILED: err => ({ err }),
});

/* -- reducers --  */
export const reducer = handleActions(
  {
    [fetchProjectsSucceeded]: (state, { payload: { projects } }) => {
      return projects;
    },
  },
  []
);

/* -- selectors -- */
export const projectsSelector = state => state.projects;

export const projectsSortedSelector = state =>
  projectsSelector(state).sort((x, y) => (x.location > y.location ? 1 : -1));
