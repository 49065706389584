import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { userSelector } from "store/user";

const mapStateToProps = state => ({
  isLogged: userSelector(state),
});

export default connect(mapStateToProps)(
  ({ component: Component, isLogged, ...props }) => {
    return (
      <Route
        {...props}
        render={() => {
          return isLogged ? <Component {...props} /> : <Redirect to="/login" />;
        }}
      />
    );
  }
);
