import React from "react";
import { connect } from "react-redux";

import distanceInWordsToNow from "date-fns/distance_in_words_to_now";
import locale from "date-fns/locale/it";

import { Link } from "react-router-dom";

import styled from "styled-components";

import * as ROUTES from "const/routes";

import Icon from "components/Icon";
import Sidekick from "components/Sidekick";
import { rilievoSelectorById } from "store/rilievi";
import { userSelectorById } from "store/users";

function NotificationCard(props) {
  const {
    type,
    title,
    message,
    rilievoId,
    date,
    itemId,
    projectId,
    author,
  } = props;

  let icon;

  if (type === "MARKER") icon = "location_on";
  if (type === "MEASUREMENT") icon = "straighten";
  if (type === "MARKERDATA") icon = "playlist_add";

  return (
    <Sidekick.Card
      visible
      as={Link}
      to={`${ROUTES.survey({
        projectId,
        survey: { id: rilievoId },
      })}/${type.toLowerCase()}/${itemId}`}
    >
      <Sidekick.CardWrapper>
        <Sidekick.CardSection>
          <Icon icon={icon} size="small" />
        </Sidekick.CardSection>
        <Sidekick.CardSection>
          <GG>
            <span>
              <small>{title}</small>
            </span>
            <div>{message}</div>
            <span>
              <small>
                <strong>{author}</strong>
                {`, ${distanceInWordsToNow(date, {
                  locale,
                })} fa.`}
              </small>
            </span>
          </GG>
        </Sidekick.CardSection>
      </Sidekick.CardWrapper>
    </Sidekick.Card>
  );
}

const GG = styled.div`
  font-size: 12px;
  color: white;

  > * + * {
    margin-top: 5px;
  }

  small {
  }

  span {
    display: block;
    color: #ccc;
  }
`;

export default connect((state, { rilievoId, userId }) => {
  const rilievo = rilievoSelectorById(rilievoId)(state);
  const user = userSelectorById(userId)(state);

  return {
    title: rilievo ? rilievo.location : `Rilievo ${rilievoId}`,
    author: user ? user.nome : `Utente ${userId}`,
  };
})(NotificationCard);
