import { createAction, createActions, handleActions } from "redux-actions";
import { createSelector } from "reselect";
import { combineReducers } from "redux";

export const THREED = "3D";
export const TWOD = "2D";
export const DOCUMENTS = "DOCUMENTS";

/* -- actions -- */
export const {
  meta: {
    changeView,
    editItem,
    stopEditing,
    changeAction,
    toggleSidebar,
    setActiveRilievoId,
  },
} = createActions({
  META: {
    CHANGE_VIEW: viewName => ({ viewName }),
    EDIT_ITEM: ({ type, id }) => ({ type, id }),
    STOP_EDITING: () => null,
    TOGGLE_SIDEBAR: sidebar => ({ sidebar }),
    CHANGE_ACTION: undefined,
    SET_ACTIVE_RILIEVO_ID: ({ rilievoId }) => ({ rilievoId }),
  },
});

export const resetMeta = createAction("RESET_META");

/* -- reducer -- */
export const viewReducer = handleActions(
  {
    [changeView]: (state, action) => {
      const {
        payload: { viewName },
      } = action;

      if ([THREED, TWOD, DOCUMENTS].indexOf(viewName) === -1) {
        console.error("[store/meta] Noop", action);
        return state;
      }

      return viewName;
    },
  },
  THREED
);

export const activeRilievoReducer = handleActions(
  {
    [setActiveRilievoId]: (state, { payload: { rilievoId } }) => {
      return rilievoId;
    },
  },
  null
);

export const sidebarsReducer = handleActions(
  {
    [editItem]: (state, { payload: { type, id } }) => {
      return {
        ...state,
        action: {
          type,
          id,
        },
      };
    },
    [stopEditing]: state => {
      return {
        ...state,
        action: null,
      };
    },
    [toggleSidebar]: (state, { payload: { sidebar } }) => {
      return {
        ...state,
        navigation: state.navigation === sidebar ? false : sidebar,
      };
    },
  },
  {
    navigation: null,
    action: null,
  }
);

export const actionsReducer = handleActions(
  {
    [changeAction]: (state, action) => {
      const { payload } = action;

      if (payload) {
        return payload;
      } else {
        return null;
      }
    },
  },
  null
);

export const reducer = (state, action) => {
  if (action.type === `${resetMeta}`) {
    return {
      view: THREED,
      sidebars: {
        navigation: null,
        action: null,
      },
      action: null,
    };
  }

  return combineReducers({
    view: viewReducer,
    sidebars: sidebarsReducer,
    action: actionsReducer,
    activeRilievoId: activeRilievoReducer,
  })(state, action);
};

/* -- selectors -- */
export const metaSelector = state => state.meta;

export const actionSelector = createSelector(
  metaSelector,
  state => state.action
);

export const activeViewSelector = createSelector(
  metaSelector,
  state => state.view
);

export const sidebarsSelector = createSelector(
  metaSelector,
  state => state.sidebars
);

export const actionSidebarSelector = createSelector(
  sidebarsSelector,
  state => state.action
);

export const navigationSidebarSelector = createSelector(
  sidebarsSelector,
  state => state.navigation
);

export const activeRilievoIdSelector = createSelector(
  metaSelector,
  state => state.activeRilievoId
);
