import { createSelector } from "reselect";
import { createActions, handleActions } from "redux-actions";

import apiClient from "utility/apiClient";

/* -- actions -- */
export const fetchUsers = () => async dispatch => {
  dispatch(fetchUsersRequest());

  try {
    const data = await apiClient({ cache: false })({
      action: "get_utenti_chat",
    });

    dispatch(fetchUsersSucceeded(data));
  } catch (err) {
    dispatch(fetchUsersFailed(err));
  }
};

export const {
  fetchUsersRequest,
  fetchUsersSucceeded,
  fetchUsersFailed,
} = createActions({
  FETCH_USERS_REQUEST: projectId => ({ projectId }),
  FETCH_USERS_SUCCEEDED: users => ({ users }),
  FETCH_USERS_FAILED: err => ({ err }),
});

/* -- reducers --  */
export const reducer = handleActions(
  {
    [fetchUsersSucceeded]: (state, { payload: { users } }) => {
      return users;
    },
  },
  []
);

/* -- selectors -- */
export const usersSelector = state => state.users;

export const userSelectorById = userId =>
  createSelector(
    usersSelector,
    users => users.find(user => userId === user.id)
  );
