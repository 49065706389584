import { createActions, handleActions } from "redux-actions";

import apiClient from "utility/apiClient";
import produce from "immer";

const api = {
  fetch: ({ rilievoId }) =>
    apiClient()({
      action: "get_documenti",
      data: {
        id_rilievo: rilievoId,
      },
    }),
};

/** -- actions -- **/
export const {
  fetchDocumentsRequest,
  fetchDocumentsSucceeded,
  fetchDocumentsFailed,
} = createActions(
  "FETCH_DOCUMENTS_REQUEST",
  "FETCH_DOCUMENTS_SUCCEEDED",
  "FETCH_DOCUMENTS_FAILED"
);

export const fetchDocuments = ({ rilievoId }) => async dispatch => {
  dispatch(fetchDocumentsRequest({ rilievoId }));

  try {
    const documents = await api.fetch({ rilievoId });
    dispatch(fetchDocumentsSucceeded({ documents, rilievoId }));
  } catch (err) {
    dispatch(fetchDocumentsFailed(err));
  }
};

/** -- reducer -- **/
export const reducer = handleActions(
  {
    [fetchDocumentsRequest]: (state, action) =>
      produce(state, draft => {
        draft.loading = true;
      }),
    [fetchDocumentsSucceeded]: (state, action) =>
      produce(state, draft => {
        draft.loading = false;
        draft.data = action.payload.documents;
      }),
  },
  {
    loading: false,
    data: [],
  }
);
