import styled from "styled-components";

export const Container = styled.div`
  padding: 0.5rem 1rem;
  background-color: rgba(32, 32, 32, 0.9);

  position: fixed;
  right: 2rem;
  bottom: 2rem;
  width: 320px;
  border-radius: 6px;
  overflow: hidden;

  z-index: 1000;
`;

export const Single = styled.div``;

export const Buttons = styled.div`
  display: flex;

  > * + * {
    margin-left: 10px;
  }

  > button {
    color: #999;
    background-color: #ccc;
    border: none;
    height: 32px;
    width: 32px;
  }
`;

export const Flex = styled.div`
  margin-top: 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 12px;
`;

export const Close = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;
