import { createActions, handleActions } from "redux-actions";
import produce from "immer";
import { createSelector } from "reselect";

import apiClient from "utility/apiClient";

function massageObjectForStore(object) {
  const { id, titolo, obj_url, mtl_url } = object;

  return {
    id,
    title: titolo,
    x: parseFloat(object.cord_x),
    y: parseFloat(object.cord_y),
    z: parseFloat(object.cord_z),
    alpha: parseInt(object.rot_x),
    beta: parseInt(object.rot_y),
    gamma: parseInt(object.rot_z),

    objUrl: obj_url,
    mtlUrl: mtl_url,

    // add props that are not persisted
    visible: true,
    loading: true,
  };
}

/* -- actions -- */
export const fetchObjects = ({ rilievoId }) => async dispatch => {
  dispatch(fetchObjectsRequest({ rilievoId }));

  try {
    const objects = await apiClient({ cache: false })({
      action: "get_obj",
      data: {
        id_rilievo: rilievoId,
      },
    });

    dispatch(fetchObjectsSucceeded({ objects, rilievoId }));
  } catch (err) {
    console.log(err);
    dispatch(fetchObjectsFailed(err));
  }
};

export const {
  fetchObjectsRequest,
  fetchObjectsSucceeded,
  fetchObjectsFailed,
} = createActions({
  FETCH_OBJECTS_REQUEST: undefined,
  FETCH_OBJECTS_SUCCEEDED: ({ objects, rilievoId }) => {
    return {
      objects: objects.map(massageObjectForStore),
    };
  },
  FETCH_OBJECTS_FAILED: err => ({ err }),
});

export const setObjectVisibility = ({ id, visible }) => dispatch =>
  dispatch(updateObjectRequest({ id, name: "visible", value: visible }));

export const { updateObjectRequest, mergeObjectsSettings } = createActions(
  "UPDATE_OBJECT_REQUEST",
  "MERGE_OBJECTS_SETTINGS"
);

export const updateObject = ({ id, name, value }) => async (
  dispatch,
  getState
) => {
  dispatch(updateObjectRequest({ id, name, value }));
};

/* -- reducers --  */
export const reducer = handleActions(
  {
    [fetchObjectsSucceeded]: (state, { payload: { objects } }) =>
      produce(state, draft => {
        draft.data = draft.data.concat(objects);
      }),
    [mergeObjectsSettings]: (state, { payload: { settings } }) =>
      produce(state, draft => {
        draft.data.forEach((object, i) => {
          let mergedObject = settings.find(
            // eslint-disable-next-line eqeqeq
            mergedObject => mergedObject.id == object.id
          );

          if (typeof mergedObject !== "undefined") {
            draft.data[i] = {
              ...object,
              ...mergedObject,
            };
          }
        });
      }),
    [updateObjectRequest]: (state, action) =>
      produce(state, draft => {
        draft.data.forEach((item, i) => {
          if (item.id === action.payload.id) {
            draft.data[i] = objectReducer(item, action);
          }
        });
      }),
  },
  {
    data: [],
    loading: false,
  }
);

const objectReducer = handleActions(
  {
    [updateObjectRequest]: (state, { payload: { name, value } }) =>
      produce(state, draft => {
        draft[name] = value;
      }),
  },
  {}
);

/* -- selectors -- */
export const objectsSelector = state => state.objects.data;

export const objectsSelectorByRilievoId = rilievoId =>
  createSelector(
    objectsSelector,
    objects => objects.filter(object => object)
  );

export const objectSelectorById = id =>
  createSelector(
    objectsSelector,
    objects => objects.find(object => object.id === id)
  );
