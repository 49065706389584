import React from "react";
import posed, { PoseGroup } from "react-pose";

import Btn from "components/Btn";

import * as S from "./styles";

function Toast(props) {
  const { items = [], onCloseClick, hide } = props;
  const [index, setIndex] = React.useState(0);

  function next() {
    setIndex(index + 1 >= items.length ? 0 : index + 1);
  }

  function prev() {
    setIndex(index - 1 < 0 ? items.length - 1 : index - 1);
  }

  if (items.length === 0) return null;

  return (
    <Container pose={hide ? "exit" : "enter"} initialPose={"exit"}>
      <S.Close>
        <Btn circle icon="close" size="small" onClick={onCloseClick} />
      </S.Close>

      <PoseGroup>{<Single key={index}>{items[index]}</Single>}</PoseGroup>

      {items.length > 1 && (
        <S.Flex>
          <div data-testid="index">
            <strong>{index + 1}</strong>/{items.length}
          </div>

          <S.Buttons>
            <Btn
              data-testid="prev"
              onClick={prev}
              circle
              icon="keyboard_arrow_left"
            />
            <Btn
              data-testid="next"
              onClick={next}
              circle
              icon="keyboard_arrow_right"
            />
          </S.Buttons>
        </S.Flex>
      )}
    </Container>
  );
}

const Container = posed(S.Container)({
  enter: {
    y: 0,
    opacity: 1,
    delay: 1000,
  },
  exit: {
    y: 100,
    opacity: 0,
  },
});

const Single = posed(S.Single)({
  enter: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: 200,
    opacity: 0,
  },
});

export default Toast;
