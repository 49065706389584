import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import {
  fetchNotifications,
  notificationsSelectorByPublic,
} from "store/notifications";

import Toast from "components/Toast";
import NotificationCard from "components/NotificationCard";
import { fetchUsers } from "store/users";

function Root({ fetchNotifications, fetchUsers, notifications, ...props }) {
  const [dismissed, setDismissed] = React.useState(false);

  React.useEffect(() => {
    fetchNotifications();
    fetchUsers();

    const timeout = setTimeout(() => {
      fetchNotifications();
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Route
      render={() => (
        <>
          {notifications.length !== 0 && (
            <Toast
              hide={dismissed}
              onCloseClick={() => setDismissed(true)}
              items={notifications.map(notification => (
                <NotificationCard {...notification} />
              ))}
            />
          )}
          {props.children}
        </>
      )}
    />
  );
}

const actions = {
  fetchNotifications,
  fetchUsers,
};

function mapStateToProps(state) {
  return {
    notifications: notificationsSelectorByPublic(state),
  };
}

export default connect(
  mapStateToProps,
  actions
)(Root);
