import axios from "axios";

const defaultOptions = {
  url: "index.php",
  useCache: false,
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const client = (passedOptions = {}) => ({ data, action }) => {
  const options = {
    ...defaultOptions,
    ...passedOptions,
  };

  const { store, persistor } = require("store");
  const { userSelector, updateToken } = require("store/user");

  const { url, endpoint } = options;

  const user = userSelector(store.getState());

  const formData = new FormData();

  const reqData = {
    ...data,
    ...user,
    clientid: process.env.REACT_APP_CLIENT_ID,
  };

  if (options.help) {
    reqData.help = true;
  }

  for (let key in reqData) {
    if (typeof reqData[key] !== "undefined") {
      formData.append(key, reqData[key]);
    }
  }

  const client = axios.post(
    endpoint || `${process.env.REACT_APP_MS}/${url}?action=${action}`,
    formData,
    {
      headers: {
        ...defaultOptions.headers,
        ...passedOptions.headers,
      },
    }
  );

  console.groupCollapsed(`[apiClient] request ${action}`);
  console.table(data);
  console.groupEnd();

  return client
    .then(x => x.data)
    .then(res => {
      const { token } = res;

      if (token) {
        store.dispatch(updateToken(token));
      }

      return res.data;
    })
    .catch((err, x) => {
      if (err.response.data.error.code === "Wrong token") {
        persistor.purge();
        store.dispatch({ type: "USER_LOGOUT" });
      } else {
        throw new Error(err.response.data.error.code);
      }
    });
};

window.apiClient = client;

export default client;

export const UPLOAD_ACTIONS = {
  MARKER_DATA: "dato",
  VIEW: "vista",
  OBJ: "obj",
};

export const uploader = (
  action,
  { markerId, rilievoId, obj, relativePath } = {}
) => file => {
  return client({ endpoint: "https://files.primis.live" })({
    data: {
      action,
      valore: file,
      id_marker: markerId,
      id_rilievo: rilievoId,
      obj,
      rel_path: relativePath,

      token:
        "mnjhgruy457839erwiuodskjfhgrtuy456789erwiodsakjfhgrtuy84579eiodnbrgyt3ut5jruehdfgty7584oruewijfhdks0",
      userid: 11,
      clientid: "799EF369809D90C8C6ECF93E31F89957",
    },
  });
};
